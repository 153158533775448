import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { LogoSVG } from 'assets/icons/logo';
import useWindowDimensions from 'hooks/useWindowDimensions.hook';
import { useAppSelector } from 'hooks/useRedux';
import { MoreFunctionsList } from './components/moreFunctionsList';
import s from './index.module.scss';

export const Header = () => {
  const location = useLocation();
  const [isListOpen, setIsListOpen] = useState(false);
  const moreBtnEl = useRef(null);
  const { isNotebook } = useWindowDimensions();
  const { isChatActive } = useAppSelector((state) => state.chatReducer);

  return (
    <motion.header
      // initial='initial'
      // animate='animate'
      // variants={{
      //   initial: { opacity: 0 },
      //   animate: { opacity: 1 }
      // }}
      // exit={{ opacity: 0 }}
      // transition={{ type: 'spring', duration: 0.5, damping: 18, stiffness: 100 }}
      initial="initial"
      animate={isChatActive && !isNotebook ? 'hidden' : 'visible'}
      exit={{ opacity: 0 }}
      variants={{
        initial: { opacity: 1 },
        visible: { opacity: 1 },
        hidden: { opacity: 0, height: 0, margin: 0, padding: 0 },
      }}
      transition={{ duration: 0.3, type: 'spring', damping: 20 }}
      className={s.header}>
       <MoreFunctionsList isOpen={isListOpen} setIsOpen={setIsListOpen} btnEl={moreBtnEl} />
      <div className={[s.header__inner, s.container].join(' ')}>
        <div className={s.header__inner_logo}>
          <Link to="/" className={s.header__logobox}>
            <LogoSVG/>
            {/* {activePaths.filter(path => location.pathname.includes(path.path)).map(path => */}
            {/*  <p className={s.header__logobox_text}> */}
            {/*    {path.description} */}
            {/*  </p> */}
            {/* )} */}
              <p className={s.header__logobox_text}>
                Автоподбор <br/> для всех!
              </p>
          </Link>
        </div>

        <div className={s.header__inner_nav}>
          {/* { isTabletLarge ? */}
          {/* <> */}
            {/* <div className={s.left}> */}
            {/*  {activePaths.map(path => */}
            {/*    <Link key={path.id} to={path.path} className={[s.header__about, location.pathname.includes(path.path) ? s.active : null ].join(' ')}> */}
            {/*      <p>{path.title}</p> */}
            {/*    </Link> */}
            {/*  )} */}
            {/* </div> */}

            {/* <div className={s.right}> */}
            {/*  <Link to={aboutPath.path} className={[s.header__about, location.pathname.includes(aboutPath.path) ? s.active : null ].join(' ')}> */}
            {/*    <p>{aboutPath.title}</p> */}
            {/*  </Link> */}
            {/* </div> */}
          {/* </> */}
          {/*  : */}
          {/*  <Burger moreBtnEl={moreBtnEl} isListOpen={isListOpen} setIsListOpen={setIsListOpen} /> */}
          {/* } */}


        </div>
      </div>
    </motion.header>
  );
};
