import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import s from './index.module.scss';
import { Chat } from './components/chat';

export const ChatBot = () =>
    <AnimatePresence>
      <motion.section
        initial="initial"
        animate='animate'
        exit={{ opacity: 0 }}
        variants={{
          initial: { opacity: 0 },
          animate: { opacity: 1 },
          open: { opacity: 1 }
        }}
        transition={{ duration: 0.3, type: 'spring', damping: 20 }} className={s.wrapper}>
        <Chat/>

      </motion.section>
    </AnimatePresence>;
