/* eslint-disable no-param-reassign, no-else-return */

import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { SendSVG } from 'assets/icons/send';
import {
  authAIAPI,
  openAIAPI } from 'services/OpenAI';
import { formatDateTo12HourClock } from 'utils/currentDate/index';
import {
  ChatSubjects,
  initialChatItems,
  initialChatContent,
  LastBotStep, currentMouth
} from 'common/chat/constants';
import {
  ChatContentType,
  InitialChatContentType,
  InteractionBotType, stepOfMessage,
  UserFieldType
} from 'common/chat/types';
import useWindowDimensions from 'hooks/useWindowDimensions.hook';
import { CustomNumericFormat } from 'form/input';
import { TypingLoader } from 'components/typingLoader';
import { IntroContainer } from 'pages/chatBot/components/chat/components/intro';
import { ResetLightSVG } from 'assets/icons/reset';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { chatActions } from 'store/reducers/UserSlice';
import { ChatItems } from './components/chatItems';
import { IChoices } from './types';
import s from './index.module.scss';

export const Chat = () => {
  const [createChatContent, { data: openAIData, isLoading, isSuccess } ] = openAIAPI.useCreateChatContentMutation({});
  const [ createAuthAccessApiAI, { data: authData, isSuccess: isAuthSuccess } ] = authAIAPI.useCreateAuthAccessApiAIMutation({});
  const { register, handleSubmit, setValue, reset } = useForm();
  const chatItemRef = useRef(null);
  const chatLoadingItemRef = useRef(null);
  const inputRef = useRef(null);
  const submitEl = useRef(null);
  const [ isChatDisable, setIsChatDisable ] = useState<boolean>(true);
  const { isNotebook } = useWindowDimensions();
  const moreItem: React.MutableRefObject<HTMLDivElement | null> | null = useRef(null);

  const formattedDate = useMemo(() => formatDateTo12HourClock(new Date()), []);
  
  const dispatch = useAppDispatch();
  const { isChatActive } = useAppSelector((state) => state.chatReducer);

  const removeDuplicates = (data: any, key: any) => [
  // @ts-ignore
    ...new Map(data?.map((item: any) => [key(item), item])).values()
  ];

  const [defaultChatContent, setDefaultChatContent] = useState<InitialChatContentType[]>(initialChatContent);

  const [defaultChatItems, setDefaultChatItems] = useState<ChatContentType>(initialChatItems);

  const [chat, setChat] = useState<ChatContentType>(defaultChatItems);
  const onSubmit = () => {
    const { botStep, userStep, chatUserMessage, botContentCurrentStep } = chat;
    const newBotStep = botStep + 1;
    const newUserStep = userStep + 1;
    const refreshStep = botContentCurrentStep + 1;

    const updatedChatContent = [
      ...chat.chatContent,
      { ...chatUserMessage, id: `${ChatSubjects.USER}${newUserStep}` },
      // @ts-ignore
      ...defaultChatContent.filter((item) => item.step === refreshStep).map((item) => ({
        ...item,
        id: `${ChatSubjects.BOT}${newBotStep}`,
        step: newBotStep,
        dateSent: formattedDate,
      })),
    ];
    if (refreshStep === LastBotStep.STEP && ( isAuthSuccess && authData )) {
      createChatContent({ temperature: 0.7, access_token: authData.access_token, model: 'GigaChat:latest', messages: [ {
        role: 'user',
        content: `${Object.values(chat.requestMessage).join(' ')}.
        При подборе вариантов по бюджету учти что мы подбираем автомобиль в России в ${currentMouth[new Date().getMonth().toString()]} ${new Date().getFullYear()} года.
        Предлагай только варианты автомобилей, подходящие под все описанные выше критерии.
        Не забывай проверять, что предлагаешь варианты, которые действительно подходят под мой бюджет в указанном диапазоне годов выпуска.
        Всегда указывай цену.` } ] });
    }
    // @ts-ignore
    setChat(prevChat => ({
      ...prevChat,
      botStep: newBotStep,
      userStep: newUserStep,
      chatContent: updatedChatContent,
      botContentCurrentStep: refreshStep,
      chatUserMessage: {}
    }));

    reset();
  };

  useEffect(() => {
    createAuthAccessApiAI({ scope: 'GIGACHAT_API_PERS' });
  }, []);

  const handleChange = (e: ChangeEvent<HTMLInputElement> | any) => {

    if (e?.value) {
      setChat({ ...chat, chatUserMessage: {
        type: ChatSubjects.USER,
        message: e?.value,
        id: `${ChatSubjects.USER}${chat.userStep}`,
        dateSent: formattedDate,
        nextStep: chat.userStep + 1,
        step: chat.userStep,
        fieldType: UserFieldType.NUMBER
      } });
    }

    if (e?.target?.value || e?.value) {
      setChat((prevChat) => {
        const updatedChatContent = chat.lastChatBot?.interactionType === InteractionBotType.CHOSE ? prevChat?.chatContent?.map((chatItem: InitialChatContentType) => {
          if (chatItem?.type === ChatSubjects.BOT) {
            if (chatItem?.choices?.length) {
              chatItem.choices = chatItem.choices.map(choice => {
                choice.chosen = false;
                return choice;
              });
            }
          }
          return chatItem;
        }) : prevChat.chatContent;

        const updatedUserContent = {
          type: ChatSubjects.USER,
          message: e?.target?.value || e?.value,
          id: `${ChatSubjects.USER}${prevChat.userStep}`,
          dateSent: formattedDate,
          nextStep: chat.userStep + 1,
          step: chat.userStep,
          fieldType: UserFieldType.NUMBER
        };

        const chosenAdditionalContent = chat.lastChatBot?.interactionType === InteractionBotType.CHOSE ?
          chat.chosenAdditionalContent.filter((chosenItem: any) => chosenItem?.step !== chat.lastChatBot.step)
          :
          removeDuplicates([
            ...chat.chosenAdditionalContent,
            {
              type: ChatSubjects.BOT,
              userMessage: e?.target?.value || e?.value,
              chosenContent: [],
              id: `${ChatSubjects.BOT}${chat.botStep}`,
              dateSent: formattedDate,
              step: chat.botStep
            }
          ], (key: any) => key.id);

        return {
          ...prevChat,
          chatContent: updatedChatContent,
          chatUserMessage: updatedUserContent,
          chosenAdditionalContent,
          // @ts-ignore
          requestMessage: { ...chat.requestMessage, [chat.botContentCurrentStep]: chat.botContentCurrentStep >= LastBotStep.STEP ? updatedUserContent.message : `${stepOfMessage[chat.botContentCurrentStep]} ${updatedUserContent.message}.` },
        };
      });
    } else {
      setChat((prevChat) => ({
        ...prevChat,
        chatUserMessage: {},
        chosenAdditionalContent: [],
      }));
    }
  };

  const handleClickChoice = (choiceItem: IChoices, chatItem: InitialChatContentType) => {
    const chosenItems = chatItem.choices?.map((item) => {
      if (choiceItem.id === item.id) {
        item.chosen = !item.chosen;
      }
      return item;
    });

    const updatedChatContent = chat.chatContent.map((item) => {
      if (item.id === chatItem.id) {
        return {
          ...item,
          choices: chosenItems,
        };
      } else {
        return item;
      }
    });

    const userMessage = {
      id: `${ChatSubjects.USER}${chat.userStep + 1}`,
      message: chosenItems?.filter((choice) => choice?.chosen).map((choice) => choice?.title).join(', '),
      type: ChatSubjects.USER,
      dateSent: formattedDate,
      nextStep: chat.userStep + 1,
      step: chat.userStep,
      fieldType: UserFieldType.STRING
    };
    // @ts-ignore
    setChat((prevChat) => ({
      ...prevChat,
      chatContent: updatedChatContent,
      chatUserMessage: userMessage,
      // @ts-ignore
      requestMessage: { ...chat.requestMessage, [chat.botContentCurrentStep]: `${stepOfMessage[chat.botContentCurrentStep]} ${chosenItems?.filter((choice) => choice?.chosen).map((choice) => choice?.value).join(', ')}.` },
      chosenAdditionalContent: removeDuplicates([ ...chat.chosenAdditionalContent, { dateSent: formattedDate, step: chat.botStep, type: ChatSubjects.BOT, id: `${ChatSubjects.BOT}${chat.botStep}`, userMessage: chosenItems?.filter((choice) => choice?.chosen).map((choice) => choice?.value).join(', '), chosenContent: chosenItems?.filter(chose => chose.chosen) }], (key: any) => key.id),
    }));
  };

  useEffect(() => {
    // Прокрутка чата вниз при добавлении новых сообщений
    if (chatItemRef.current) {
      // @ts-ignore
      chatItemRef.current.scrollTop = chatItemRef.current?.scrollHeight;
    }

    if (chatLoadingItemRef) {
      // @ts-ignore
      chatLoadingItemRef.current.scrollTop = chatLoadingItemRef.current?.scrollHeight;
    }
  }, [chat.chatContent]);

  const startChat = () => {
    setIsChatDisable(false);

    const { botStep, userStep, chatContent, botContentCurrentStep } = chat;
    const newBotStep = botStep + 1;
    const newUserStep = userStep + 1;
    const refreshStep = botContentCurrentStep + 1;
    const newChatContent = [
      ...chatContent,
      {
        id: `${ChatSubjects.BOT}${newBotStep}`,
        interactionType: InteractionBotType.CHOSE,
        dateSent: formattedDate,
        type: ChatSubjects.BOT,
        message: 'Какой кузов автомобиля вас интересует ? (можно выбрать несколько вариантов)',
        step: newBotStep,
        completedStep: false,
        choices: [
          { id: 0, value: 'Купе', title: 'Купе', chosen: false },
          { id: 1, value: 'Внедорожник', title: 'Внедорожник', chosen: false },
          { id: 2, value: 'Седан', title: 'Седан', chosen: false },
          { id: 3, value: 'Хэтчбек', title: 'Хэтчбек', chosen: false },
          { id: 4, value: 'Кроссовер', title: 'Кроссовер', chosen: false },
          { id: 5, value: 'Универсал', title: 'Универсал', chosen: false },
          { id: 6, value: 'Внедорожник', title: 'Внедорожник', chosen: false },
          { id: 7, value: 'Минивэн', title: 'Минивэн', chosen: false },
          { id: 8, value: 'Пикап', title: 'Пикап', chosen: false },
        ],
      },
    ];

    setChat((prevChat) => ({
      ...prevChat,
      botStep: newBotStep,
      userStep: newUserStep,
      botContentCurrentStep: refreshStep,
      chatContent: newChatContent,
    }));
  };

  const resetChat = async () => {
    setIsChatDisable(false);
    await setDefaultChatContent(defaultChatContent.reduce((itemAcc: InitialChatContentType[], item) => {

      itemAcc.push({
        ...item,
        choices: item.choices ? item.choices.map((choiceItem) => ({ ...choiceItem, chosen: false })) : undefined
      });

      return itemAcc;
    }, []));
    setChat((prevChat) => {
      const newBotStep = prevChat.botStep + 1;
      const newUserStep = prevChat.userStep + 1;
      const refreshStep = prevChat.botContentCurrentStep + 1;
      return {
        ...prevChat,
        botStep: newBotStep,
        userStep: newUserStep,
        botContentCurrentStep: refreshStep,
        chatContent: [
          ...prevChat.chatContent,
          {
            ...defaultChatContent[0],
            id: `${ChatSubjects.BOT}${newBotStep}`,
            step: newBotStep,
            dateSent: formattedDate,
          },
        ],
      };
    });
  };

  const handleResetChat = async () => {
    await setDefaultChatContent(defaultChatContent.reduce((itemAcc: InitialChatContentType[], item) => {

      itemAcc.push({
        ...item,
        choices: item.choices ? item.choices.map((choiceItem) => ({ ...choiceItem, chosen: false })) : undefined
      });

      return itemAcc;
    }, []));
    dispatch(chatActions.handleActiveChat({ isChatActive: false }));
    setChat(defaultChatItems);
    setIsChatDisable(true);

  };

  useEffect(() => {
    const lastBotMessage = chat.chatContent.slice().reverse().find((item) => item.type === ChatSubjects.BOT);

    if (lastBotMessage) {
      setChat((prevChat) => ({
        ...prevChat,
        lastChatBot: lastBotMessage,
      }));
    }
  }, [chat.chatContent]);

  useEffect(() => {
    if (isSuccess && chat.botContentCurrentStep === LastBotStep.STEP && openAIData?.choices?.[0]?.message?.content) {
      setChat({ ...chat,
        botStep: chat.botStep + 1,
        userStep: chat.userStep + 1,
        botContentCurrentStep: 0,
        chosenAdditionalContent: [],
        lastChatBot: {},
        chatContent: [ ...chat.chatContent.map(item => {
          if (item?.choices?.length) {
            item.choices = item.choices.map((choice: IChoices) => {
              choice.chosen = false;
              return choice;
            });
          }
          return item;
        }), {
          id: `${ChatSubjects.BOT}${chat.botStep + 1}`,
          interactionType: InteractionBotType.DEFAULT,
          dateSent: formattedDate,
          type: ChatSubjects.BOT,
          message: openAIData?.choices?.[0]?.message?.content,
          step: chat.botStep + 1,
          completedStep: false,
          choices: [],
        },
        {
          id: `${ChatSubjects.USER}${chat.userStep + 1}`,
          interactionType: InteractionBotType.DEFAULT,
          dateSent: formattedDate,
          type: ChatSubjects.USER,
          isStartUserStep: true,
          message: 'Новый подбор',
          step: chat.userStep + 1,
          completedStep: false,
          choices: [],
        }],
        requestMessage: { 0: 'Предложи пожалуйста 5 подходящих вариантов автомобиля по следующим критериям.' },
        chatUserMessage: {}
      });

      setIsChatDisable(true);
    }
  }, [isSuccess, chat.botStep]);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {

      if (event.code === 'Enter' && submitEl.current) {
        // @ts-ignore
        submitEl.current?.click();
      }
    };

    window?.addEventListener('keypress', handleKeyPress);

    return () => {
      window.removeEventListener('keypress', handleKeyPress);
    };
  }, []);

  useEffect(() => {
    if (chat.botStep === LastBotStep.STEP) {
      setIsChatDisable(true);
    }
  }, [chat.botStep]);


  useEffect(() => {
    if (chat.chatContent.length >= 3) {
      dispatch(chatActions.handleActiveChat({ isChatActive: true }));
    }
  }, [chat.chatContent.length]);

  useEffect(() => {
    if (moreItem.current) {
      moreItem.current.style.position = 'absolute';
    }
  }, [chat.chatContent.length]);

  return (
    <div className={s.wrapper}>
      <IntroContainer isNotebook={isNotebook} isChatActive={isChatActive} />
      <div className={s.wrapper__chat}>
        <div ref={chatItemRef} className={s.chat}>
          {chat.chatContent.map((chatItem, index: number) => (
            <ChatItems onSubmit={onSubmit} chat={chat} handleChatActive={!isChatDisable ? resetChat : startChat} handleClickChoice={handleClickChoice} key={chatItem.id} index={index} lastChatBot={chat.lastChatBot} chatItem={chatItem} />
          ))}
          <TypingLoader chatLoadingItemRef={chatLoadingItemRef} isTyping={isLoading} />
        </div>
        <div className={s.gap} />
      </div>

      <form className={s.form} onSubmit={handleSubmit(onSubmit)}>
        { chat.lastChatBot.interactionType === InteractionBotType.ENTERNUMBER ?
          <CustomNumericFormat
            id='user'
            {...register('user')}
            ref={inputRef}
            onValueChange={handleChange}
            setValue={setValue}
            placeholder='Введите текст здесь'
            thousandSeparator={' '}
            onKeyPress={(event: any) => {

              if (inputRef?.current) {
                // @ts-ignore
                inputRef?.current?.blur();
              }

              if (!/[0-9]/.test(event.key) && chat.lastChatBot.interactionType === InteractionBotType.ENTERNUMBER) {
                event.preventDefault();
              }
            }}
            className={s.input}
          />
          :
          <input
            className={s.input}
            id='user'
            value={chat.chatUserMessage?.message || ''}
            {...register('user', { onChange: handleChange })}
            placeholder='Введите текст здесь'
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key) && chat.lastChatBot.interactionType === InteractionBotType.ENTERNUMBER) {
                event.preventDefault();
              }
            }}
            type={chat.lastChatBot.interactionType === InteractionBotType.ENTERNUMBER ? 'number' : 'text' }
            disabled={(chat.lastChatBot.interactionType === InteractionBotType.CHOSE) || isLoading || isChatDisable
          }
          />
        }

        <button
          disabled={chat.chatContent.length <= 2}
          type='button'
          className={s.reset}
          ref={submitEl}
          onClick={handleResetChat}
        >
          <ResetLightSVG />
        </button>

        <button
          disabled={(!chat?.chatUserMessage?.message && !chat?.lastChatBot?.chosen?.length) || isLoading || isChatDisable
        }
          type='submit'
          className={s.submit}
          id='userSubmit'
          ref={submitEl}
        >
          <SendSVG />
        </button>
      </form>
    </div>
  );
};