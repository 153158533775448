
export enum Paths {
  main = '/',
  chatBot = 'chat-bot',
  calculator = 'calculator',
  compute = 'calculator/compute',
  calculatorResult = 'calculator/result',
  about = 'about',
  notFound = '*'
}
