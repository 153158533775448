import React from 'react';
import './App.css';
import MainRouter from './router';

function App() {
  return (
    <MainRouter />
  );
}

export default App;
