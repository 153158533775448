import React from 'react';
import { motion } from 'framer-motion';
import introCar from 'assets/img/intro-car.webp';
import s from './index.module.scss';

interface IIntroContainer {
  isChatActive: boolean;
  isNotebook: boolean;
}

export const IntroContainer = (props: IIntroContainer) => {
  const { isChatActive, isNotebook } = props;

  return (
    <motion.div
      initial="initial"
      animate={isChatActive && !isNotebook ? 'hidden' : 'visible'}
      exit={{ opacity: 0 }}
      variants={{
        initial: { opacity: 1 },
        visible: { opacity: 1 },
        hidden: { opacity: 0, height: 0, margin: 0, padding: 0 },
      }}
      transition={{ duration: 0.3, type: 'spring', damping: 20 }}
      className={s.wrapper}
    >
      <div className={s.wrapper__content}>
        <h2 className={s.title}>
          С искусственным интеллектом вы найдете идеальный автомобиль для себя!
        </h2>
      </div>

      <div className={s.wrapper__img}>
        <div className={s.inner}>
          <img className={s.img} src={introCar} alt="Car" />
        </div>
      </div>
    </motion.div>
  );
};
