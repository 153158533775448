import React, { useEffect, useState } from 'react';
import { Variants, motion } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import { Footer } from 'containers/footer/index';
import { Header } from 'containers/header/index';
import { AnimatedDivPage } from 'components/animatedPage';
import { Loader } from 'components/loader';
import { IMainLayoutProps } from './types';
import s from './index.module.scss';

export const MainLayout = ({ children }: IMainLayoutProps) => {
  const [isDOMReady, setDOMReady] = useState(false);
  const [isAnimationComplete, setIsAnimationComplete] = useState(false);
  const [isLoadingAnimationComplete, setIsLoadingAnimationComplete] = useState(false);
  const [isLoadingAnimationEnd, setIsLoadingAnimationEnd] = useState(false);
  const location = useLocation();
  const pathsShouldLoading = ['/', '/calculator/result', '/calculator', '/chat-bot'];

  const animations: Variants = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 }
  };

  useEffect(() => {
    setDOMReady(true);
  }, []);

  useEffect(() => {
    if (!pathsShouldLoading.includes(location.pathname)) {
      setIsLoadingAnimationComplete(true);
    }
  }, []);

  return (
    <>
      <AnimatedDivPage onAnimationComplete={() => setIsAnimationComplete(true)}
           transition={{ duration: 0.5 }} animations={animations}
           styles={{
             // minHeight: 'calc(100vh - 9rem)'
           }} className={s.wrapper}>
          { isLoadingAnimationComplete ?
            <>
            <div className={s.wrapper__inner}>
              <Header />
              <motion.main
                onAnimationComplete={() => setIsAnimationComplete(true)}
                initial='initial'
                animate='animate'
                exit={{ opacity: 0 }}
                variants={{
                  initial: { opacity: 0 },
                  animate: { opacity: 1 }
                }}
                className={s.main}
                transition={{ duration: 0.5 }}>{children || null}</motion.main>
              <div className={s.ads__placeholder} />
            </div>
            <Footer />
            </>
            :
            null }
      </AnimatedDivPage>

        { pathsShouldLoading.includes(location.pathname) ?
          <Loader hidden={isAnimationComplete} onAnimationComplete={() => {
            setIsLoadingAnimationComplete(true);
          }} />
          :
          null
        }

    </>
  );
};
