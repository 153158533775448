import React from 'react';

export const LogoSVG = () => <svg width="90" height="22" viewBox="0 0 90 22" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M15.6048 6.18024H11.8962C11.8285 5.71016 11.6902 5.29261 11.4814 4.9276C11.2725 4.55706 11.0044 4.24183 10.677 3.9819C10.3496 3.72197 9.97139 3.52288 9.54239 3.38462C9.11904 3.24636 8.65899 3.17723 8.16226 3.17723C7.26475 3.17723 6.48296 3.39568 5.81688 3.83258C5.1508 4.26395 4.63431 4.89442 4.26741 5.72398C3.9005 6.54801 3.71705 7.54902 3.71705 8.727C3.71705 9.93816 3.9005 10.9558 4.26741 11.7798C4.63996 12.6038 5.15927 13.226 5.82535 13.6463C6.49142 14.0666 7.26193 14.2768 8.13686 14.2768C8.62795 14.2768 9.08235 14.2132 9.50006 14.086C9.92341 13.9588 10.2988 13.7735 10.6262 13.5302C10.9536 13.2813 11.2245 12.9799 11.439 12.6259C11.6592 12.272 11.8116 11.8683 11.8962 11.4148L15.6048 11.4314C15.5089 12.2112 15.269 12.9633 14.8851 13.6878C14.5069 14.4067 13.9961 15.051 13.3526 15.6207C12.7147 16.1848 11.9527 16.6327 11.0665 16.9646C10.1859 17.2908 9.1896 17.454 8.07759 17.454C6.53094 17.454 5.14798 17.1111 3.92872 16.4253C2.71511 15.7396 1.75551 14.7469 1.04992 13.4472C0.349972 12.1476 0 10.5742 0 8.727C0 6.87431 0.355617 5.29814 1.06685 3.99849C1.77809 2.69884 2.74333 1.7089 3.96259 1.02866C5.18185 0.342886 6.55352 0 8.07759 0C9.08235 0 10.0137 0.13826 10.8717 0.414781C11.7354 0.691302 12.5002 1.09502 13.1663 1.62594C13.8324 2.15133 14.3743 2.79563 14.792 3.55882C15.2153 4.32202 15.4863 5.19583 15.6048 6.18024Z"
    fill="#2269F3"/>
  <path
    d="M20.921 17.4623C20.0912 17.4623 19.3517 17.3213 18.7026 17.0392C18.0534 16.7516 17.5398 16.3286 17.1616 15.77C16.789 15.2059 16.6027 14.5035 16.6027 13.6629C16.6027 12.955 16.7354 12.3605 17.0007 11.8793C17.266 11.3982 17.6273 11.0111 18.0845 10.7179C18.5417 10.4248 19.061 10.2036 19.6424 10.0543C20.2295 9.90498 20.8447 9.7999 21.4882 9.73907C22.2446 9.66164 22.8543 9.58974 23.3171 9.52338C23.78 9.45148 24.1159 9.34641 24.3247 9.20814C24.5336 9.06988 24.638 8.86526 24.638 8.59427V8.54449C24.638 8.0191 24.4687 7.61262 24.13 7.32504C23.7969 7.03746 23.3228 6.89367 22.7075 6.89367C22.0584 6.89367 21.5419 7.03469 21.158 7.31674C20.7742 7.59326 20.5202 7.94168 20.396 8.36199L17.06 8.09653C17.2293 7.32227 17.5623 6.65309 18.0591 6.08899C18.5558 5.51936 19.1965 5.08245 19.9811 4.77828C20.7714 4.46858 21.6858 4.31373 22.7244 4.31373C23.447 4.31373 24.1384 4.39668 24.7989 4.56259C25.4649 4.72851 26.0548 4.98567 26.5685 5.33409C27.0878 5.6825 27.497 6.13047 27.7962 6.67798C28.0954 7.21996 28.245 7.86978 28.245 8.62745V17.2217H24.8243V15.4548H24.7227C24.5138 15.8529 24.2344 16.2041 23.8844 16.5083C23.5345 16.8069 23.1139 17.042 22.6228 17.2134C22.1317 17.3793 21.5644 17.4623 20.921 17.4623ZM21.9539 15.0234C22.4845 15.0234 22.953 14.9211 23.3595 14.7164C23.7659 14.5063 24.0848 14.2242 24.3162 13.8703C24.5477 13.5163 24.6634 13.1154 24.6634 12.6674V11.3152C24.5505 11.3871 24.3953 11.4535 24.1977 11.5143C24.0058 11.5696 23.7885 11.6222 23.5457 11.6719C23.303 11.7162 23.0603 11.7577 22.8176 11.7964C22.5749 11.8296 22.3547 11.86 22.1571 11.8876C21.7338 11.9485 21.3641 12.0452 21.048 12.178C20.7319 12.3107 20.4863 12.4904 20.3113 12.7172C20.1363 12.9384 20.0488 13.2149 20.0488 13.5468C20.0488 14.0279 20.2267 14.3957 20.5823 14.6501C20.9435 14.8989 21.4008 15.0234 21.9539 15.0234Z"
    fill="#2269F3"/>
  <path
    d="M30.0896 17.2217V4.47964H33.5865V6.70287H33.722C33.9591 5.91202 34.357 5.31473 34.9158 4.91101C35.4747 4.50176 36.1182 4.29713 36.8463 4.29713C37.027 4.29713 37.2217 4.30819 37.4306 4.33032C37.6394 4.35244 37.8229 4.38286 37.9809 4.42157V7.55731C37.8116 7.50754 37.5773 7.4633 37.2782 7.42459C36.979 7.38587 36.7052 7.36652 36.4568 7.36652C35.9262 7.36652 35.4521 7.47989 35.0344 7.70664C34.6223 7.92785 34.2949 8.23756 34.0522 8.63575C33.8151 9.03394 33.6966 9.49296 33.6966 10.0128V17.2217H30.0896Z"
    fill="#2269F3"/>
  <path
    d="M42.8631 17.2217L37.9014 0.232278H41.9063L44.7767 12.037H44.9206L48.0873 0.232278H51.5165L54.6747 12.0618H54.8271L57.6974 0.232278H61.7024L56.7407 17.2217H53.1675L49.8654 6.11388H49.7299L46.4362 17.2217H42.8631Z"
    fill="#2269F3"/>
  <path
    d="M64.9546 17.4623C64.1249 17.4623 63.3854 17.3213 62.7363 17.0392C62.0871 16.7516 61.5735 16.3286 61.1953 15.77C60.8227 15.2059 60.6364 14.5035 60.6364 13.6629C60.6364 12.955 60.7691 12.3605 61.0344 11.8793C61.2997 11.3982 61.6609 11.0111 62.1182 10.7179C62.5754 10.4248 63.0947 10.2036 63.6761 10.0543C64.2632 9.90498 64.8784 9.7999 65.5219 9.73907C66.2783 9.66164 66.888 9.58974 67.3508 9.52338C67.8137 9.45148 68.1496 9.34641 68.3584 9.20814C68.5673 9.06988 68.6717 8.86526 68.6717 8.59427V8.54449C68.6717 8.0191 68.5023 7.61262 68.1637 7.32504C67.8306 7.03746 67.3565 6.89367 66.7412 6.89367C66.0921 6.89367 65.5756 7.03469 65.1917 7.31674C64.8079 7.59326 64.5539 7.94168 64.4297 8.36199L61.0937 8.09653C61.263 7.32227 61.596 6.65309 62.0928 6.08899C62.5895 5.51936 63.2302 5.08245 64.0148 4.77828C64.8051 4.46858 65.7195 4.31373 66.7581 4.31373C67.4807 4.31373 68.1721 4.39668 68.8326 4.56259C69.4986 4.72851 70.0885 4.98567 70.6022 5.33409C71.1215 5.6825 71.5307 6.13047 71.8299 6.67798C72.1291 7.21996 72.2787 7.86978 72.2787 8.62745V17.2217H68.858V15.4548H68.7564C68.5475 15.8529 68.2681 16.2041 67.9181 16.5083C67.5681 16.8069 67.1476 17.042 66.6565 17.2134C66.1654 17.3793 65.5981 17.4623 64.9546 17.4623ZM65.9876 15.0234C66.5182 15.0234 66.9867 14.9211 67.3932 14.7164C67.7996 14.5063 68.1185 14.2242 68.3499 13.8703C68.5814 13.5163 68.6971 13.1154 68.6971 12.6674V11.3152C68.5842 11.3871 68.429 11.4535 68.2314 11.5143C68.0395 11.5696 67.8222 11.6222 67.5794 11.6719C67.3367 11.7162 67.094 11.7577 66.8513 11.7964C66.6085 11.8296 66.3884 11.86 66.1908 11.8876C65.7675 11.9485 65.3978 12.0452 65.0816 12.178C64.7655 12.3107 64.52 12.4904 64.345 12.7172C64.17 12.9384 64.0825 13.2149 64.0825 13.5468C64.0825 14.0279 64.2603 14.3957 64.616 14.6501C64.9772 14.8989 65.4344 15.0234 65.9876 15.0234Z"
    fill="#2269F3"/>
  <path
    d="M75.8844 22C75.4272 22 74.9982 21.9641 74.5975 21.8922C74.2023 21.8258 73.8749 21.7401 73.6153 21.635L74.4281 18.997C74.8515 19.1242 75.2325 19.1933 75.5712 19.2044C75.9155 19.2154 76.2118 19.138 76.4602 18.9721C76.7142 18.8062 76.9203 18.5241 77.0783 18.1259L77.29 17.5867L72.6246 4.47964H76.4179L79.1104 13.8371H79.2459L81.9638 4.47964H85.7825L80.7276 18.5988C80.4849 19.2846 80.1547 19.8818 79.737 20.3906C79.3249 20.905 78.8028 21.3004 78.1706 21.5769C77.5384 21.859 76.7763 22 75.8844 22Z"
    fill="#2269F3"/>
  <path
    d="M87.9679 17.4374C87.4091 17.4374 86.9293 17.2438 86.5285 16.8567C86.1334 16.4641 85.9358 15.994 85.9358 15.4465C85.9358 14.9045 86.1334 14.4399 86.5285 14.0528C86.9293 13.6657 87.4091 13.4721 87.9679 13.4721C88.5098 13.4721 88.9839 13.6657 89.3904 14.0528C89.7968 14.4399 90 14.9045 90 15.4465C90 15.8115 89.904 16.1461 89.7121 16.4502C89.5258 16.7489 89.2803 16.9894 88.9755 17.1719C88.6707 17.3489 88.3348 17.4374 87.9679 17.4374Z"
    fill="#2269F3"/>
</svg>;
