import { useState, useEffect } from 'react';

interface IWindowDimensions {
  isDesktop: boolean;
  isNotebook: boolean;
  isTablet: boolean;
  isTabletLong: boolean;
  isTabletSmall: boolean;
  isTabletLarge: boolean;
  isMobile: boolean;
}

export default function useWindowDimensions(): IWindowDimensions {
  function getWindowDimensions() {
    const windowWidth = window.innerWidth;

    return windowWidth;
  }

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  function handleResize() {
    setWindowDimensions(getWindowDimensions());
  }

  const currentWindowDimensions: IWindowDimensions = {
    isDesktop: windowDimensions >= 1200,
    isNotebook: windowDimensions >= 1024,
    isTabletLong: windowDimensions >= 830,
    isTablet: windowDimensions >= 768,
    isTabletLarge: windowDimensions >= 793,
    isTabletSmall: windowDimensions >= 560,
    isMobile: windowDimensions >= 480,
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return { ...currentWindowDimensions };
}
