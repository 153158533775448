import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { currenciesAPI } from 'services/CurrencyService';
import { openAIAPI, authAIAPI } from 'services/OpenAI';
import { chatReducer } from './reducers/UserSlice';
// import userReducer from './reducers/UserSlice';

const rootReducer = combineReducers({
  // userReducer,
  [currenciesAPI.reducerPath]: currenciesAPI.reducer,
  [openAIAPI.reducerPath]: openAIAPI.reducer,
  [authAIAPI.reducerPath]: authAIAPI.reducer,
  chatReducer,
});

export const setupStore = () => configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(currenciesAPI.middleware, openAIAPI.middleware, authAIAPI.middleware)
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
