import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { Index } from '../models/main';

// @ts-ignore
export const currenciesAPI = createApi({
  reducerPath: 'currenciesAPI',
  baseQuery: fetchBaseQuery({ baseUrl: 'https://www.cbr-xml-daily.ru' }),
  tagTypes: ['Currencies'],
  endpoints: (build) => ({
    fetchAllCurrencies: build.query<Index, void>({
      query: () => ({
        url: '/daily_json.js'
      }),
      providesTags: result => ['Currencies']
    }),
  })
});
