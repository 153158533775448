import React from 'react';
import mac from 'assets/img/mac_book_pro.webp';

export const About = () => {
  const about = 1;
  return (
    <section className="about">
      <div className="wrapper about__wrapper">
        <div className="about__left">
          <h1 className="about__title">Чем полезен калькулятор CarWay</h1>
          <div className="about__car">
            <img src={mac} alt="MacBook" />
          </div>
        </div>
        <div className="about__right">
          <div className="why-block">
            <h2 className="why-block__title text-24">Доступ к расчету пошлин, налогов и сборов</h2>
            <p className="why-block__text text-18">
              Калькулятор автоматически обращается к формулам для расчета таможенных сборов, таможенных пошлин,
              утилизационного сбора для физических лиц. В случае с юридическими лицами, калькулятор дополнительно
              выводит расчет акциза и НДС ввоза. Вы узнаете актуальную на сегодня полную стоимость налогов и сборов с
              учетом цены автомобиля.
            </p>
            <p className="why-block__text text-18">При сборке нашего калькулятора можно получить полную информацию о
              расходах и платежах за автомобиль.</p>
          </div>
          <div className="advantages-block">
            <h2 className="advantages-block__title text-24">Автоматический расчет:</h2>
            <div className="advantages-block__list">
              <div className="advantages-block__item advantages-item">
                <p className="advantages-item__count">1</p>
                <p className="advantages-item__text">Таможенного сбора</p>
              </div>
              <div className="advantages-block__item advantages-item">
                <p className="advantages-item__count">2</p>
                <p className="advantages-item__text">Таможенной пошлины</p>
              </div>
              <div className="advantages-block__item advantages-item">
                <p className="advantages-item__count">3</p>
                <p className="advantages-item__text">Утилизационного сбора</p>
              </div>
              <div className="advantages-block__item advantages-item">
                <p className="advantages-item__count">4</p>
                <p className="advantages-item__text">Акциза</p>
              </div>
              <div className="advantages-block__item advantages-item">
                <p className="advantages-item__count">5</p>
                <p className="advantages-item__text">НДС</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
