/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// import {fetchUsers} from "./ActionCreators";

// interface UserSlice {
//     users: IUser[];
//     isLoading: boolean;
//     error: string;
// };
//
// const initialState: UserSlice = {
//     users: [],
//     isLoading: false,
//     error: '',
// }
//
// export const userSlice = createSlice({
//     name: 'user',
//     initialState,
//     reducers: {
//         // usersFetching(state) {
//         //     state.isLoading = true;
//         // },
//         // usersFetchingSuccess(state, action: PayloadAction<IUser[]>) {
//         //     state.isLoading = false;
//         //     state.error = '';
//         //     state.users = action.payload;
//         // },
//         // usersFetchingError(state, action: PayloadAction<string>) {
//         //     state.isLoading = false;
//         //     state.error = action.payload;
//         //
//         // }
//     },
//     extraReducers: (builder) => {
//         builder
//             .addCase(fetchUsers.pending.type, (state) => {
//                 state.isLoading = true;
//             })
//             .addCase(fetchUsers.fulfilled.type, (state, action: PayloadAction<IUser[]>) => {
//                 state.users = action.payload;
//                 state.isLoading = false;
//                 state.error = '';
//             })
//             .addCase(fetchUsers.rejected.type, (state, action: PayloadAction<string>) => {
//                 state.error = action.payload;
//                 state.isLoading = false;
//             })
//     }
// })
//
// export default userSlice.reducer;

interface ChatSlice {
  isChatActive: boolean;
}

const initialState: ChatSlice = {
  isChatActive: false,
};

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    handleActiveChat(state, action: PayloadAction<ChatSlice>) {
      state.isChatActive = action.payload.isChatActive;
    },
  },
});

export const chatReducer = chatSlice.reducer;
export const chatActions = chatSlice.actions;
