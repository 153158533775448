import React from 'react';
import { HTMLMotionProps } from 'framer-motion';
import s from './index.module.scss';
import { Typing } from './components/Typing';

interface ITypingLoader extends HTMLMotionProps<'div'> {
  // styles?: any;
  isTyping: boolean;
  chatLoadingItemRef: React.MutableRefObject<any>;
}

export const TypingLoader = ({ chatLoadingItemRef, isTyping }: ITypingLoader) =>
    <div ref={chatLoadingItemRef} className={s.wrapper}> { isTyping ? <Typing /> : null } </div>;
