import React, { ReactChild, ReactChildren } from 'react';
import { HTMLMotionProps, motion, Transition, Variants, AnimatePresence } from 'framer-motion';

interface IAnimatedPageProps extends HTMLMotionProps<'div'> {
  children: ReactChild | ReactChildren | ReactChild[] | null | any;
  animations: Variants;
  transition?: Transition;
  className?: string;
  styles?: any;
  ref?: any;
}


export const AnimatedDivPage = ({ children, animations, transition, styles, ref, className, ...other }: IAnimatedPageProps) => (

    <motion.div
      className={className}
      style={styles}
      ref={ref}
      transition={transition}
      variants={animations}
      initial="initial"
      animate="animate"
      exit="exit"
      {...other}
      >
      {/* @ts-ignore */}
      {children}
    </motion.div>
);
