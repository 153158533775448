import { formatDateTo12HourClock } from 'utils/currentDate';
import { InitialChatContentType, InteractionBotType } from './types';

export enum ChatSubjects {
  BOT = 'bot',
  USER = 'user',
  TYPING = 'typing',
}

export enum LastBotStep {
  STEP = 6
}

export const initialChatContent: InitialChatContentType[] = [
  {
    id: null,
    interactionType: InteractionBotType.ENTERNUMBER,
    dateSent: '',
    type: ChatSubjects.BOT,
    message: 'Примерный бюджет на покупку автомобиля в ₽ (ввод цифрами)',
    step: 2,
    choices: [],
    isAbleToSkipStep: false
  },
  {
    id: null,
    interactionType: InteractionBotType.CHOSE,
    dateSent: '',
    type: ChatSubjects.BOT,
    message: 'Какие характеристики автомобиля для вас приоритетны ?',
    step: 3,
    choices: [{ id: 9, value: 'Надежный', title: 'Надежный', chosen: false }, { id: 10, value: 'Премиальный', title: 'Премиальный', chosen: false }, {
      id: 11,
      value: 'Спортивный', title: 'Спортивный',
      chosen: false
    }, { id: 12, value: 'Семейный', title: 'Семейный', chosen: false }, { id: 13, value: 'Вместительный', title: 'Вместительный', chosen: false }],
    isAbleToSkipStep: false
  },
  {
    id: null,
    interactionType: InteractionBotType.CHOSE,
    dateSent: '',
    type: ChatSubjects.BOT,
    message: 'Какие автомобили вы рассматриваете - новые или подержанные (Б/У) ?',
    step: 4,
    choices: [{ id: 14, title: 'Новые', value: `${new Date().getFullYear() - 1}, ${new Date().getFullYear()}`, chosen: false }, { id: 15, title: 'Б/У до 3-х лет', value: `${new Date().getFullYear() - 2}, ${new Date().getFullYear() - 1}, ${new Date().getFullYear()}`, chosen: false }, {
      id: 16,
      title: 'Б/У до 5-ти лет',
      value: `${new Date().getFullYear() - 4}, ${new Date().getFullYear() - 3}, ${new Date().getFullYear() - 2}, ${new Date().getFullYear() - 1}, ${new Date().getFullYear()}`,
      chosen: false
    }, { id: 17, title: 'Б/У до 7-ти лет',
      value: `${new Date().getFullYear() - 6}, ${new Date().getFullYear() - 5}, ${new Date().getFullYear() - 4}, ${new Date().getFullYear() - 3}, ${new Date().getFullYear() - 2}, ${new Date().getFullYear() - 1}, ${new Date().getFullYear()}`,
      chosen: false }, {
      id: 18,
      title: 'Б/У старше 7-ми лет',
      value: '2024 год и старше',
      chosen: false
    }, { id: 19, title: 'Не важно', value: 'Любой год старше 2024 года', chosen: false }],
    isAbleToSkipStep: false
  },
  {
    id: null,
    interactionType: InteractionBotType.DEFAULT,
    dateSent: '',
    type: ChatSubjects.BOT,
    message: 'Опишите любые дополнительные пожелания и требования к автомобилю.',
    step: 5,
    choices: [],
    isAbleToSkipStep: true
  },
];

export const typingBotMessage = [
  { id: 4, word: 'П' },
  { id: 5, word: 'и' },
  { id: 6, word: 'ш' },
  { id: 7, word: 'е' },
  { id: 8, word: 'т' },
  { id: 9, word: '.' },
  { id: 10, word: '.' },
  { id: 11, word: '.' },
];

export const currentMouth: any = {
  0: 'январе',
  1: 'феврале',
  2: 'марте',
  3: 'апреле',
  4: 'мае',
  5: 'июне',
  6: 'июле',
  7: 'августе',
  8: 'сентябре',
  9: 'октябре',
  10: 'ноябре',
  11: 'декабре',
};

export const initialChatItems = {
  userStep: 0,
  botStep: 0,
  botContentCurrentStep: 0,
  chatContent: [
    {
      id: `${ChatSubjects.BOT}0`,
      interactionType: InteractionBotType.DEFAULT,
      dateSent: formatDateTo12HourClock(new Date()),
      type: ChatSubjects.BOT,
      message: 'Привет!\n' +
        'Я бот для подбора автомобиля.\n' +
        'Ответь на несколько вопросов и расскажи о своих пожеланиях - я предложу 5 подходящих вариантов автомобиля.',
      step: 0,
      completedStep: true,
      choices: [],
    },
    {
      id: `${ChatSubjects.USER}0`,
      interactionType: InteractionBotType.DEFAULT,
      dateSent: formatDateTo12HourClock(new Date()),
      type: ChatSubjects.USER,
      isStartUserStep: true,
      message: 'Начать',
      step: 0,
      completedStep: true,
      choices: [],
    },
  ],
  lastChatBot: {},
  // TODO: Сделать объектом, в конце если это последний шаг, складывать все результаты сообщения, добавлять если пользователь хочет ввести что-то от себя, и отправлять.
  requestMessage: { 0: 'Представь что ты эксперт по подбору автомобилей, как новых, так и Б/У. Предложи пожалуйста 5 подходящих вариантов автомобиля по следующим критериям.' },
  chatUserMessage: {},
  chosenAdditionalContent: [],
};