import React from 'react';

export const NotFound = () => {
  const notFound = 1;
  return (
    <div>
      NotFound page
    </div>
  );
};
