/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { HTMLMotionProps, motion } from 'framer-motion';
import { LoaderSVG } from 'assets/icons/loader';
import s from './index.module.scss';

interface ILoader extends HTMLMotionProps<'div'> {
  styles?: any;
  hidden?: boolean;
}

export const Loader = ({ styles, hidden, ...other }: ILoader) =>
      <motion.div
      initial='initial'
      animate={ hidden ? { opacity: 0, scale: 0.7, zIndex: -1 } : { opacity: 1, scale: 1 } }
      exit={{ opacity: 0, scale: 0.7 }}
      variants={{
        initial: { opacity: 0, scale: 0.7 },
        animate: { opacity: 1, scale: 1 }
      }}
      transition={{ duration: 0.3, type: 'spring', damping: 20 }}
      style={styles} className={['loader', 'card', 'is-loading', 'loader__active', s.loader__addition].join(' ')}
      {...other}>
      <LoaderSVG/>
    </motion.div>
  ;
