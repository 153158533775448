import { v4 as uuidv4 } from 'uuid';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { authAIAPIResponse, IOpenAI, IOpenAIAuth, IOpenAIResponse } from 'models/openAI';

const apiKey = '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';
const authAIKey = 'NWRlNzdhZDItZDE0Yi00N2FlLWIyZWEtYWNmNGM3YThjNzViOjkwZTdjNmQ4LWVjZTMtNDJmYy04M2JjLTU5MTE4MTEzMjJjYw==';
const API_BASE_URL = 'https://api.carway.asap-lp.ru';
const RqUID = uuidv4();

export const authAIAPI = createApi({
  reducerPath: 'authAIAPI',
  baseQuery: fetchBaseQuery({
    baseUrl: API_BASE_URL,
    prepareHeaders(headers) {
      headers.set('Authorization', `Bearer ${authAIKey}`);
      headers.set('RqUID', RqUID);
      return headers;
    },
  }),
  tagTypes: ['Token'],
  endpoints: (build) => ({
    createAuthAccessApiAI: build.mutation<authAIAPIResponse, IOpenAIAuth>({
      query: (openAiBody) => {
        const formData = new URLSearchParams();
        formData.append('scope', openAiBody.scope);
        const bodyString = formData.toString();
        return {
          url: '/oauth',
          method: 'POST',
          body: bodyString,
          credentials: 'include',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${authAIKey}`,
            'RqUID': RqUID,
          },
        };
      },
    }),
  }),
});

export const openAIAPI = createApi({
  reducerPath: 'openAIAPI',
  baseQuery: fetchBaseQuery({
    baseUrl: API_BASE_URL, prepareHeaders(headers) {
      headers.set('Origin', '*');
      return headers;
    }
  }),
  tagTypes: ['OpenAI'],
  endpoints: (build) => ({
    createChatContent: build.mutation<IOpenAIResponse, IOpenAI>({
      query: (openAiBody) => ({
        url: '/chat',
        method: 'POST',
        body: openAiBody,
        credentials: 'include',
        headers: {
          'Authorization': `Bearer ${openAiBody.access_token}`,
          'Origin': '*',
        }
      }),
    }),
  })
});

// export const openAIAPI = createApi({
//   reducerPath: 'openAIAPI',
//   baseQuery: fetchBaseQuery({
//     baseUrl: 'https://api.openai.com/v1', prepareHeaders(headers) {
//       headers.set('Authorization', `Bearer ${apiKey}`);
//       headers.set('Origin', 'https://carway-app.ru/');
//       return headers;
//     }
//   }),
//   tagTypes: ['OpenAI'],
//   endpoints: (build) => ({
//     createChatContent: build.mutation<IOpenAIResponse, IOpenAI>({
//       query: (openAiBody) => ({
//         url: '/chat/completions',
//         method: 'POST',
//         body: openAiBody
//       })
//     }),
//   })
// });
