import React, { FC } from 'react';
import { Routes, Route, useLocation, Location } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { IRoutes } from '../paths/types';
import { routes } from '../paths';

export const Container: FC = () => {
  const location: Location = useLocation();
  return (
    <AnimatePresence mode={'wait'}>
      <Routes location={location} key={location.pathname}>
        {routes.map((route: IRoutes) =>
          <Route path={route.path} key={route.id} element={route.element}/>
        )}
      </Routes>
    </AnimatePresence>
  );
};
