import React from 'react';
import { motion } from 'framer-motion';
import { ChatSubjects } from 'common/chat/constants';
import { ChatContentType, InitialChatContentType } from 'common/chat/types';
import { IChoices } from '../../types';
import s from './index.module.scss';

interface IChatItems {
  chatItem: InitialChatContentType;
  lastChatBot: InitialChatContentType;
  handleClickChoice: (choiceItem: IChoices, chatItem: InitialChatContentType) => void;
  handleChatActive: () => void;
  index: number;
  chat: ChatContentType;
  onSubmit: () => void;
}

export const ChatItems =
  ({
     onSubmit,
     chatItem,
     handleClickChoice,
     index,
     lastChatBot,
     handleChatActive,
     chat
   }: IChatItems) =>

    <motion.div
      initial="initial"
      animate="animate"
      exit={{ opacity: 0 }}
      variants={{
        initial: { opacity: 0, x: chatItem.type === ChatSubjects.BOT ? '-100px' : '100px' },
        animate: { opacity: 1, x: '0' },
        open: { opacity: 1 }
      }}
      transition={{ duration: index * 0.3, type: 'spring', damping: 18, stiffness: 100 }}
      className={s.wrapper}>
      {chatItem.type === ChatSubjects.BOT ?
        <div className={s.bot}>
          <p className={s.bot__message}>{chatItem.message}</p>
          {chatItem?.choices?.length ?
            <div className={s.choices}>
              {chatItem.choices.map((choice: IChoices) =>
                <button
                  disabled={chatItem.step !== chat.botStep} onClick={(e: any) => {
                  e.preventDefault();
                  handleClickChoice(choice, chatItem);
                  e.target.blur();
                }}
                  className={[s.button, choice.chosen ? s.chosen : null].join(' ')} type="button"
                  key={choice.id}>{choice.title}</button>
              )}
            </div>
            :
            null
          }
          {chatItem.isAbleToSkipStep &&
            <div className={[s.skip__button_wrapper].join(' ')}>
              <button disabled={chatItem.step !== chat.botStep} onClick={onSubmit}
                      className={[s.skip__button].join(' ')} type="button"><p>Нет пожеланий</p></button>
            </div>
          }
          <p className={s.bot__date}>{chatItem.dateSent}</p>

        </div>
        :
        null
      }

      {chatItem.type === ChatSubjects.USER ?
        <div className={s.user}>
          <div className={s.content}>
            {chatItem?.isStartUserStep
              ?
              <button
                disabled={chatItem.step !== chat.botStep} onClick={(e: any) => {
                e.preventDefault();
                handleChatActive();

                // handleClickChoice(choice, chatItem);
                // e.target.blur();
              }}
                className={[s.button].join(' ')} type="button" key={chatItem.id}>
                <p>{chatItem.message}</p>
              </button>
              :
              <>
                <p className={s.user__message}>{chatItem.message}</p>
                <p className={s.bot__date}>{chatItem.dateSent}</p>
              </>
            }
          </div>
        </div>
        :
        null
      }
    </motion.div>;