import { IChoices } from 'pages/chatBot/components/chat/types';

export type InitialChatContentType = {
  id: string | null,
  message: string,
  step?: number | null,
  type: string;
  choices?: IChoices[],
  dateSent: string;
  completedStep?: boolean;
  isStartUserStep?: boolean;
  interactionType: number;
  isAbleToSkipStep: boolean;
};

export enum InteractionBotType {
  ENTER = 0,
  ENTERNUMBER = 1,
  CHOSE = 2,
  DEFAULT = 3
}

export enum UserFieldType {
  STRING = 0,
  NUMBER = 1,
}

export type InitialUserChat = InitialChatContentType & { nextStep?: number, fieldType: string | number };

export interface ChatContentType {
  userStep: number;
  botStep: number;
  chatContent: InitialChatContentType[] | any[];
  chatUserMessage: InitialUserChat | any;
  lastChatBot: InitialChatContentType | any;
  chosenAdditionalContent: any;
  botContentCurrentStep: any;
  requestMessage: any;
}

export const stepOfMessage = {
  0: 'Привет, предложи пожалуйста 5 подходящих вариантов автомобиля. по следующим критериям - ',
  1: 'Тип кузова:',
  2: 'Не предлагай варианты автомобилей, которые значительно дороже или значительно дешевле данной суммы. Примерный бюджет на покупку автомобиля в рублях:',
  3: 'Приоритетно, чтобы автомобиль был:',
  4: 'Год выпуска автомобиля:',
  5: 'Дополнительные пожелания:',
};