import React from 'react';
import { motion } from 'framer-motion';
import s from './index.module.scss';

export const Footer = () => {
  const footer = 0;

  return (
    <motion.footer
      initial='initial'
      animate='animate'
      variants={{
        initial: { opacity: 0 },
        animate: { opacity: 1 }
      }}
      exit={{ opacity: 0 }}
      transition={{ type: 'spring', duration: 0.5, damping: 18, stiffness: 100 }}
      className={[s.footer].join(' ')}>
      <div className={s.footer__wrapper}>
        <a href="mailto:help@carway-app.ru" className={s.description}>Связаться с нами</a>
        <p className={s.description}>©Все права защищены.</p>
        <p className={[s.footer__date, s.description].join(' ')}>2023</p>
      </div>
    </motion.footer>
  );
};
