import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Container } from './container';

const MainRouter = () => (
  <BrowserRouter>
    <Container/>
  </BrowserRouter>
);

export default React.memo(MainRouter);
