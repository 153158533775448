import React from 'react';
import { MainLayout } from 'containers/layout/mainLayout';
import { CalculatorResult } from 'pages/calculatorResult';
import { Calculator } from 'pages/calculator';
import { NotFound } from 'pages/notFound';
import { ChatBot } from 'pages/chatBot';
import { About } from 'pages/about';
import { Main } from 'pages/main';
import { IRoutes } from './types';
import { Paths } from './paths';


export const routes: IRoutes[] = [
  {
    id: 1,
    path: Paths.calculator,
    element:
      <MainLayout>
        {/* <Main/> */}
        <ChatBot/>
      </MainLayout>,
  },

  {
    id: 2,
    path: Paths.main,
    element:
      <MainLayout>
        {/* <Main/> */}
        <ChatBot/>
      </MainLayout>,
  },

  {
    id: 3,
    path: Paths.chatBot,
    element:
      <MainLayout>
        <ChatBot/>
      </MainLayout>,
  },

  // {
  //   id: 4,
  //   path: Paths.compute,
  //   element:
  //     <MainLayout>
  //       <Calculator/>
  //     </MainLayout>,
  // },

  // {
  //   id: 5,
  //   path: Paths.calculatorResult,
  //   element:
  //     <MainLayout>
  //       <CalculatorResult/>
  //     </MainLayout>,
  // },

  {
    id: 6,
    path: Paths.about,
    element:
      <MainLayout>
        <About/>
      </MainLayout>,
  },

  {
    id: 7,
    path: Paths.notFound,
    element:
      <MainLayout>
        <NotFound/>
      </MainLayout>,
  },
];

export const isExistentPath = (route: string): boolean => routes.map((item: IRoutes) => item.path).includes(route);
