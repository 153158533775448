import React, { memo, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { motion, MotionStyle } from 'framer-motion';
import { MoreFunctionsBtn } from './components';
import s from './index.module.scss';

interface IPropsMoreFunctionsList {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  btnEl: React.MutableRefObject<any>;
  styles?: MotionStyle;
}

export const MoreFunctionsList = memo((props: IPropsMoreFunctionsList) => {
  const { isOpen, setIsOpen, btnEl, styles } = props;
  const listEl = useRef<any>();
  const location = useLocation();

  const handleImport = () => {
    // showModal(ModalImportEmployees);
  };

  const moreElements: any[] = [
    {
      id: 1,
      item: <MoreFunctionsBtn title={'Бот'} handleFunc={handleImport} />,
      path: '/chat-bot',
    },
    {
      id: 2,
      item: <MoreFunctionsBtn title={'Калькулятор'} handleFunc={handleImport} />,
      path: '/calculator',
    },
    {
      id: 3,
      item: <MoreFunctionsBtn title={'О проекте'} handleFunc={handleImport} />,
      path: '/about',
    },
  ];

  const handleWindow = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const isOpenAddEmployeeMenu = (e: MouseEvent) =>
      !listEl.current?.contains(e.target) &&
      !btnEl.current?.contains(e.target) &&
      setIsOpen(false);

    document.addEventListener('click', isOpenAddEmployeeMenu);
    return () => document.removeEventListener('click', isOpenAddEmployeeMenu);
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'scroll';
    }
  }, [isOpen]);

  return (
    <motion.div
      className={s.wrapper}
      style={{
        pointerEvents: isOpen ? 'auto' : 'none',
        borderColor: isOpen ? 'white' : 'gray',
        backdropFilter: isOpen ? 'blur(3px)' : 'blur(0)',
        ...styles,
      }}
      initial="hidden"
      animate={isOpen ? 'open' : 'hidden'}
      exit="hidden" // Добавляем выходную анимацию при закрытии
      variants={{
        hidden: { opacity: 0, x: '-100%' },
        open: { opacity: 1, x: '0' },
      }}
      transition={{
        type: 'spring',
        damping: 17
        // duration: 1,
      }}
    >
      <div className={s.close}>
        <motion.button
          onClick={handleWindow}
          type="button"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
        />
      </div>

      <ul>
        {moreElements.map((item: any, index: number) => (
          <Link key={item.id} to={item.path}>
            <motion.li
              ref={listEl}
              className={[location.pathname.includes(item.path) ? s.active : null ].join(' ')}
              initial="hidden"
              animate={isOpen ? 'open' : 'hidden'}
              variants={{
                hidden: { opacity: 0, filter: 'blur(10px)' },
                open: { opacity: 1, filter: 'blur(0px)' },
              }}
              transition={{
                duration: isOpen ? 0.3 : 0,
                delay: isOpen ? 0.15 * index : 0,
              }}
            >
              {item.item}
            </motion.li>
          </Link>
        ))}
      </ul>
    </motion.div>
  );
});
