/* eslint-disable arrow-body-style, no-return-assign */
import React from 'react';
import { UseFormSetValue } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';

interface ICustomNumericFormat {
  name: string;
  placeholder?: string;
  className?: string;
  thousandSeparator?: string;
  id?: string;
  onValueChange?: any;
  onKeyPress?: any;
  setValue: UseFormSetValue<any>;
  ref?: React.MutableRefObject<any>;
}

export const CustomNumericFormat = React.forwardRef(({ name, onKeyPress, placeholder, className, thousandSeparator, setValue, ...other }: ICustomNumericFormat) => {
  return (
      <NumericFormat
        onKeyPress={onKeyPress}
        onValueChange={(inputData) => setValue(name, inputData.value)}
        placeholder={placeholder}
        className={className}
        thousandSeparator={thousandSeparator}
        {...other}
      />
  );
});
