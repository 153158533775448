import React from 'react';

export const ResetLightSVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink" width="18"
       height="18" x="0" y="0" viewBox="0 0 173.397 173.397"
       xmlSpace="preserve" fillRule="evenodd" className="">
    <g transform="matrix(1.1399999999999995,0,0,1.1399999999999995,-12.137860450744554,-12.137760581970113)">
      <path
            d="M119.863 42.189C98.234 26.226 67.607 28.035 48.021 47.62c-21.582 21.583-21.582 56.574 0 78.156 21.582 21.582 56.574 21.582 78.156 0 8.27-8.27 13.37-18.509 15.302-29.207a4.573 4.573 0 0 1 4.531-3.787h11.543a4.587 4.587 0 0 1 4.553 5.292c-2.333 15.469-9.454 30.356-21.365 42.267-29.626 29.625-77.659 29.625-107.284 0-29.626-29.626-29.626-77.66 0-107.285C61.342 5.17 105.534 3.534 135.335 28.14c1.39 1.147 3.299.605 4.246-.927l6.176-9.996c1.81-2.931 6.305-2.67 6.894.724l7.794 44.925c.472 2.721-1.735 4.928-4.456 4.456l-44.926-7.794c-3.394-.589-3.636-5.055-.723-6.894l9.085-5.736c1.764-1.114 2.118-3.472.439-4.71z"
            opacity="1" data-original="#ab53d8"/>
    </g>
  </svg>
);
